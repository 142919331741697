import { computed } from 'vue'
import { useStore } from '@/store'
import { ElNotification } from 'element-plus'

export function useReceiptState(pagename: string) {
  const store = useStore()
  const pageInfo = computed(() => store.state.system.pageInfo)
  const queryInfo = computed(() => store.state.system.queryInfo)
  const pageName = pagename
  let ReceiptData: any[] = []
  const receiptData = (item: any) => {
    ReceiptData = item
  }

  const handleRecoveryClick = () => {
    const state = 'recovery'
    const pageInfoVal = pageInfo.value
    const queryInfoVal = queryInfo.value

    if (ReceiptData.length) {
      const result = store.dispatch('system/updateReceiptState', {
        ReceiptData,
        state,
        pageInfoVal,
        queryInfoVal,
        pageName
      })
      result.then((res) => {
        if (res.code === 0) {
          ElNotification({
            title: '操作成功',
            message: res.message,
            type: 'success'
          })
        } else {
          ElNotification({
            title: '操作失败',
            message: res.message,
            type: 'error'
          })
        }
      })
    } else {
      ElNotification({
        title: '操作失败',
        message: '请选择数据再进行操作！',
        type: 'error'
      })
    }
  }

  const handleReceiveClick = () => {
    const state = 'receive'
    const pageInfoVal = pageInfo.value
    const queryInfoVal = queryInfo.value
    if (ReceiptData.length) {
      const result = store.dispatch('system/updateReceiptState', {
        ReceiptData,
        state,
        pageInfoVal,
        queryInfoVal,
        pageName
      })
      result.then((res) => {
        if (res.code === 0) {
          ElNotification({
            title: '操作成功',
            message: res.message,
            type: 'success'
          })
        } else {
          ElNotification({
            title: '操作失败',
            message: res.message,
            type: 'error'
          })
        }
      })
    } else {
      ElNotification({
        title: '操作失败',
        message: '请选择数据再进行操作！',
        type: 'error'
      })
    }
  }

  const handlePostClick = () => {
    const state = 'post'
    const pageInfoVal = pageInfo.value
    const queryInfoVal = queryInfo.value
    if (ReceiptData.length) {
      const result = store.dispatch('system/updateReceiptState', {
        ReceiptData,
        state,
        pageInfoVal,
        queryInfoVal,
        pageName
      })
      result.then((res) => {
        if (res.code === 0) {
          ElNotification({
            title: '操作成功',
            message: res.message,
            type: 'success'
          })
        } else {
          ElNotification({
            title: '操作失败',
            message: res.message,
            type: 'error'
          })
        }
      })
    } else {
      ElNotification({
        title: '操作失败',
        message: '请选择数据再进行操作！',
        type: 'error'
      })
    }
  }

  return [receiptData, handleRecoveryClick, handleReceiveClick, handlePostClick]
}
