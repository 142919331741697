import { ref } from 'vue'
import PageContent from '@/components/page-content'
import { ElNotification } from 'element-plus'

export function usePageSearch() {
  const pageContentRef = ref<InstanceType<typeof PageContent>>()
  const handleResetClick = () => {
    pageContentRef.value?.getPageData()
    ElNotification({
      title: '重置成功',
      message: '重置搜索条件成功！',
      type: 'success'
    })
  }

  const handleQueryClick = (queryInfo: any) => {
    pageContentRef.value?.getPageData(queryInfo)
    ElNotification({
      title: '搜索成功',
      message: '搜索数据成功！',
      type: 'success'
    })
  }
  return [pageContentRef, handleResetClick, handleQueryClick]
}
