
import { defineComponent, ref } from 'vue'
import HyForm from '@/base-ui/form'

export default defineComponent({
  components: {
    HyForm
  },
  props: {
    searchFormConfig: {
      type: Object,
      reuqired: true
    }
  },
  emits: ['resetBtnClick', 'queryBtnClick'],
  setup(props, { emit }) {
    // 双向绑定的属性应该是由配置文件的field来决定
    const forItems = props.searchFormConfig?.formItems ?? []
    const formOriginDate: any = {}
    for (const item of forItems) {
      formOriginDate[item.field] = ''
    }
    const formDate = ref(formOriginDate)

    // 用户点击重置清除数据
    const handleResetClick = () => {
      formDate.value = formOriginDate
      emit('resetBtnClick')
    }

    // 用户点击了搜索
    const handleQueryClick = () => {
      emit('queryBtnClick', formDate.value)
    }
    return {
      formDate,
      handleResetClick,
      handleQueryClick
    }
  }
})
