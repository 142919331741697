
import { defineComponent } from 'vue'
import { searchFormConfig } from './config/search.config'
import { contentTableConfig } from './config/content.config'

import PageSearch from '@/components/page-search'
import PageContent from '@/components/page-content'

import { usePageSearch } from '@/hooks/usePageSearch'
import { useReceiptState } from '@/hooks/useReceiptState'

export default defineComponent({
  name: 'recovery',
  components: {
    PageSearch,
    PageContent
  },
  setup() {
    const [pageContentRef, handleResetClick, handleQueryClick] = usePageSearch()
    const [
      receiptData,
      handleRecoveryClick,
      handleReceiveClick,
      handlePostClick
    ] = useReceiptState('recovery')
    return {
      searchFormConfig,
      contentTableConfig,
      pageContentRef,
      handleResetClick,
      handleQueryClick,
      handleRecoveryClick,
      handleReceiveClick,
      handlePostClick,
      receiptData
    }
  }
})
