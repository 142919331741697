import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, mergeProps as _mergeProps, normalizeStyle as _normalizeStyle, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-c5622eb2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "yh-form" }
const _hoisted_2 = { class: "header" }
const _hoisted_3 = { class: "footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_autocomplete = _resolveComponent("el-autocomplete")!
  const _component_el_input_number = _resolveComponent("el-input-number")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_date_picker = _resolveComponent("el-date-picker")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_el_form = _resolveComponent("el-form")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _renderSlot(_ctx.$slots, "header", {}, undefined, true)
    ]),
    _createVNode(_component_el_form, {
      "label-width": _ctx.labelWidth,
      rules: _ctx.rules,
      ref: "form",
      model: _ctx.modelValue
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_row, null, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.formItems, (item) => {
              return (_openBlock(), _createBlock(_component_el_col, _normalizeProps(_mergeProps({
                key: item.label
              }, _ctx.colLayout)), {
                default: _withCtx(() => [
                  (!item.isHidden)
                    ? (_openBlock(), _createBlock(_component_el_form_item, {
                        key: 0,
                        label: item.label,
                        rules: item.rules,
                        style: _normalizeStyle(_ctx.itemStyle),
                        prop: item.prop
                      }, {
                        default: _withCtx(() => [
                          (item.type === 'input' || item.type === 'password')
                            ? (_openBlock(), _createBlock(_component_el_input, {
                                key: 0,
                                placeholder: item.placeholder,
                                disabled: _ctx.disabled,
                                "show-password": item.type === 'password',
                                "model-value": _ctx.modelValue[`${item.field}`],
                                "onUpdate:modelValue": ($event: any) => (_ctx.handleValueChange($event, item.field))
                              }, null, 8, ["placeholder", "disabled", "show-password", "model-value", "onUpdate:modelValue"]))
                            : (item.type === 'autocomplete')
                              ? (_openBlock(), _createBlock(_component_el_autocomplete, {
                                  key: 1,
                                  "model-value": _ctx.modelValue[`${item.field}`],
                                  "onUpdate:modelValue": ($event: any) => (_ctx.handleValueChange($event, item.field)),
                                  "fetch-suggestions": _ctx.querySearch,
                                  placeholder: item.placeholder,
                                  "trigger-on-focus": true,
                                  disabled: _ctx.disabled,
                                  style: {"width":"100%"}
                                }, null, 8, ["model-value", "onUpdate:modelValue", "fetch-suggestions", "placeholder", "disabled"]))
                              : (item.type === 'inputnumber')
                                ? (_openBlock(), _createBlock(_component_el_input_number, {
                                    key: 2,
                                    "model-value": _ctx.modelValue[`${item.field}`],
                                    "onUpdate:modelValue": ($event: any) => (_ctx.handleValueChange($event, item.field)),
                                    min: 0,
                                    max: 10,
                                    style: {"width":"100%"}
                                  }, null, 8, ["model-value", "onUpdate:modelValue"]))
                                : (item.type === 'select')
                                  ? (_openBlock(), _createBlock(_component_el_select, _mergeProps({
                                      key: 3,
                                      placeholder: item.placeholder,
                                      disabled: _ctx.disabled
                                    }, item.otherOptions, {
                                      "model-value": _ctx.modelValue[`${item.field}`],
                                      "onUpdate:modelValue": ($event: any) => (_ctx.handleValueChange($event, item.field)),
                                      style: {"width":"100%"}
                                    }), {
                                      default: _withCtx(() => [
                                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.options, (option) => {
                                          return (_openBlock(), _createBlock(_component_el_option, {
                                            key: option.value,
                                            value: option.value
                                          }, {
                                            default: _withCtx(() => [
                                              _createTextVNode(_toDisplayString(option.title), 1)
                                            ]),
                                            _: 2
                                          }, 1032, ["value"]))
                                        }), 128))
                                      ]),
                                      _: 2
                                    }, 1040, ["placeholder", "disabled", "model-value", "onUpdate:modelValue"]))
                                  : (item.type === 'datepicker')
                                    ? (_openBlock(), _createBlock(_component_el_date_picker, _mergeProps({
                                        key: 4,
                                        style: {"width":"100%"},
                                        disabled: _ctx.disabled
                                      }, item.otherOptions, {
                                        format: "YYYY-MM-DD",
                                        "value-format": "YYYY-MM-DD",
                                        "model-value": _ctx.modelValue[`${item.field}`],
                                        "onUpdate:modelValue": ($event: any) => (_ctx.handleValueChange($event, item.field))
                                      }), null, 16, ["disabled", "model-value", "onUpdate:modelValue"]))
                                    : _createCommentVNode("", true)
                        ]),
                        _: 2
                      }, 1032, ["label", "rules", "style", "prop"]))
                    : _createCommentVNode("", true)
                ]),
                _: 2
              }, 1040))
            }), 128))
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["label-width", "rules", "model"]),
    _createElementVNode("div", _hoisted_3, [
      _renderSlot(_ctx.$slots, "footer", {}, undefined, true)
    ])
  ]))
}