import { useStore } from '@/store'
import { ref } from 'vue'

export function useAutocomplete() {
  const store = useStore()
  const restaurants = ref()
  const querySearch = (queryString: string, cb: any) => {
    const results = queryString
      ? restaurants.value.filter(createFilter(queryString))
      : restaurants.value
    // 调用 callback 返回建议列表的数据
    cb(results)
  }
  const createFilter = (queryString: string) => {
    return (restaurant: any) => {
      return (
        restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0
      )
    }
  }

  const loadAll: any = () => {
    return store.state.system.memoryList
  }

  return [restaurants, querySearch, createFilter, loadAll]
}
