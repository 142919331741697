
import { defineComponent, PropType, onMounted, ref } from 'vue'
import { IFormItem } from '../types'
import { useAutocomplete } from '@/hooks/useAutocomplete'

export default defineComponent({
  props: {
    modelValue: {
      type: Object,
      reuqired: true
    },
    formItems: {
      type: Array as PropType<IFormItem[]>
    },
    labelWidth: {
      type: String,
      default: '100px'
    },
    itemStyle: {
      type: Object,
      default: () => ({ padding: '10px 20px' })
    },
    colLayout: {
      type: Object,
      default: () => ({
        xl: 6,
        lg: 8,
        md: 12,
        sm: 24,
        xs: 24
      })
    },
    disabled: {
      type: Boolean,
      default: false
    },
    rules: {
      type: Object
    }
  },
  emits: ['update:modelValue', 'formValue'],
  setup(props, { emit }) {
    const handleValueChange = (value: any, field: string) => {
      emit('update:modelValue', { ...props.modelValue, [field]: value })
    }
    const form = ref()
    const [restaurants, querySearch, createFilter, loadAll] = useAutocomplete()
    onMounted(() => {
      restaurants.value = loadAll()
      emit('formValue', form.value)
    })

    return {
      handleValueChange,
      restaurants,
      querySearch,
      createFilter,
      form,
      loadAll
    }
  }
})
