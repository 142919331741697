import { IForm } from '@/base-ui/form'

export const searchFormConfig: IForm = {
  formItems: [
    {
      field: 'oddnumber',
      type: 'input',
      label: '运单号:',
      placeholder: '请输入运单号'
    },
    {
      field: 'consignee',
      type: 'input',
      label: '收货人:',
      placeholder: '请输入收货人'
    },
    {
      field: 'consignor',
      type: 'input',
      label: '发货人:',
      placeholder: '请输入发货人'
    }
  ]
}
